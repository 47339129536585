.lesson-builder {
    display: flex;
    height: 100vh; /* Ensure the full height of the viewport is used */
  }
  
  .left-side {
    flex: 1;
    padding: 20px;
    overflow-y: auto; /* Make the left side scrollable */
  }
  
  .right-side {
    flex: 1;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    /* height: 90%; Fix the right side to the viewport height */
    overflow: hidden; /* Prevent scrolling on the right side */
  }
  
  .lesson-preview {
    margin-top: 5%;
    margin-bottom: 5%;
    z-index: 9999;
    width: 50%;
    height: 99%;
    border: 1px solid #ccc;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    overflow-y: auto; /* Enable vertical scrolling within the preview if needed */
  }
  

  .ql-size-small {
    font-size: 0.75em;
  }
  
  .ql-size-large {
    font-size: 1.5em;
  }
  
  .ql-size-huge {
    font-size: 2.5em;
  }

  
  

  .ql-align-center {
    text-align: center;
  }
  
  .ql-align-right {
    text-align: right;
  }
  
  .ql-align-justify {
    text-align: justify;
  }
  

  .ql-header-1 {
    font-size: 2em;
  }
  
  .ql-header-2 {
    font-size: 1.5em;
  }
  
  .ql-font-serif {
    font-family: serif;
  }
  
  .ql-font-monospace {
    font-family: monospace;
  }

  .ql-bold {
    font-weight: bold;
  }
  
  .ql-italic {
    font-style: italic;
  }
  
  .ql-underline {
    text-decoration: underline;
  }
  
  .ql-strike {
    text-decoration: line-through;
  }
  

  .ql-list-ordered {
    list-style-type: decimal;
  }
  
  .ql-list-bullet {
    list-style-type: disc;
  }
  
  .ql-indent-1 {
    padding-left: 3em;
  }
  
  .ql-indent-2 {
    padding-left: 6em;
  }
  
  .ql-indent-3 {
    padding-left: 9em;
  }
  
  .ql-indent-4 {
    padding-left: 12em;
  }

  .ql-editor img {
    width: 10%;
  }

  

  
  /* Add this CSS to your existing stylesheet or create a new one */

/* LessonBuilder.css */

/* Hide the label for the SketchPicker */
.sketch-picker label {
    display: none !important;
  }

  #rc-editable-input-1 {
  width: 110% !important;
  padding: 4px 10% 3px;
  border: none;
  box-shadow: rgb(204, 204, 204) 0px 0px 0px 1px inset;
  font-size: 11px;
}
  



/* LessonBuilder.css */


.template-editor-container {
  display: flex;
  flex-direction: column;
  
  background-color: #f3e5f5; /* Light purple background */
  border: 2px solid #ce93d8; /* Thick purple border */
  padding: 10px;
  margin-bottom: 20px; /* Add spacing between each template editor */
  border-radius: 8px;
}

.template-editor-content {
  flex: 1;
  width: 100%; /* Ensure content takes the full width */
}

.template-editor-handle {
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: grab;
}

.template-editor-buttons {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 10px;
}

.template-editor-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-left: 10px;
}

.template-editor-button svg {
  fill: #ce93d8; /* Use a color that matches the border */
  width: 24px;
  height: 24px;
}


/* Add this at the end of your LessonBuilder.css file */

.save-lesson-button {
  background-color: #4caf50; /* Green color */
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px; /* Add some spacing from the top */
  /* margin-left: auto; Move to the right */
}

.save-lesson-button:hover {
  background-color: #45a049; /* Darker green on hover */
}


.toggle-json-editor{  background-color: #E6AF2E; /* Green color */
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px; /* Add some spacing from the top */
  /* margin-left: auto; Move to the right */}

  .toggle-json-editor:hover {
    background-color: #b98610; /* Darker green on hover */
  }

.refresh-container{
  margin-right: 20px;
  z-index: 40000;
}

.refresh-preview-button {
  background-color: #8f4ae2; /* Purple color */
  color: white;
  border: none;
  padding: 10px;
  font-size: 16px;
  border-radius: 50%; /* Make it circular */
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 20px; /* Add some spacing from the bottom */
  margin-right: auto; /* Align to the left of the right-side block */
  display: inline-flex; /* Ensure it only takes the necessary space */
  align-items: center;
  justify-content: center;
}

.refresh-preview-button:hover {
  background-color: #7a3bc8; /* Darker purple on hover */
}

.lesson-form-input {
  display: block;
  width: 80%;
  height: 30px;
  /* padding: 10px; */
  /* margin-bottom: 20px; Space between input boxes */
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px; /* Rounded corners */
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.lesson-form-input:focus,
.lesson-form-input:hover {
  border-color: #8f4ae2; /* Purple border on focus and hover */
  box-shadow: 0 0 8px rgba(143, 74, 226, 0.6); /* Purple border glow */
  outline: none; /* Remove default outline */
}


.number-input {
  width: 7%; /* Smaller width for number inputs */
margin-right: 1%;
/* text-align: center; */
background: none;
  border: none;
  box-shadow: none;
  color: white;
  font-size: 20px;
  font-weight: bold;
}

.form-row {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  margin: 5px;
  /* margin-bottom: 10px; */
}

.form-text {
  margin-right: 2px;
  width: 70px; /* Adjust this value to fit your design */
  font-size: 16px;
  font-weight: bold;
  color: white;
}

.lesson-form-input.empty {
  background-color: rgb(201, 188, 188); /* Different color when input is empty */
}

.lessonSelectorContainer{
  background-color: #8f4ae2;
  padding: 20px;
  border-radius: 20px;
  margin-bottom: 50px;
}

.lessonSelectorButtonsContainer{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

select {
  width: 100%;
  margin: 2px;
  height: 30px;
  font-weight: bold;
  font-size: 16px;
  /* padding: 10px; */
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
 
}

select:focus,
select:hover {
  border-color: #8f4ae2;
  box-shadow: 0 0 8px rgba(143, 74, 226, 0.6);
  outline: none;
}


.input-group {
  display: flex;
  flex-direction: row;
  align-items: center;
 padding:5px;
}

.input-group h5 {
  width:18%;
  font-size: 2vh;
  font-weight: bold;
  color: #8e24aa;
  /* margin-right: 10px; */
}

.input-group input[type="text"] {
  width: 80%;
  padding: 8px;
  border: 1px solid #ce93d8;
  border-radius: 4px;
  font-size: 14px;
}


.input-group input[type="number"] {
margin-left: 20px;
  text-align: center;
  width: 10%;
  padding: 8px;
  border: 1px solid #ce93d8;
  border-radius: 4px;
  font-size: 14px;
}

.input-group input[type="text"]:focus,
.input-group input[type="number"]:focus {
  outline: none;
  border-color: #8e24aa;
  box-shadow: 0 0 0 2px rgba(142, 36, 170, 0.2);
}

.reactquill-input-group {
  display: flex;
  flex-direction: row;
  align-items: center;
 padding:5px;
 padding-bottom: 150px;
 background-color: #decff3;
 border-radius: 10px;

}

.reactquill-input-group h5 {
  width:18%;
  font-size: 2vh;
  font-weight: bold;
  color: #8e24aa;
  /* margin-right: 10px; */
}

.reactquill-input-group input[type="text"] {
  width: 80%;
  padding: 8px;
  border: 1px solid #ce93d8;
  border-radius: 4px;
  font-size: 14px;
}


.ql-editor  {
  width: 100%;
}

.custom-quill-editor {
  width: 80%;
  margin: 0 auto;
}

/* Target the editable area */
.custom-quill-editor .ql-editor {
  width: 100%;
}

/* Ensure the toolbar (if visible) is also 80% width */
.custom-quill-editor .ql-toolbar {
  width: 100%;
}