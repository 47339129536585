:root {
    --background-light: #E5E4E2;
    --background-dark: #262c3c;
    --font-light: black;
    --font-dark: white;
  }
  
  .lightThemeFont {
    color: var(--font-light);
    transition: color 0.3s ease;
  }
  
  .darkThemeFont {
    color: var(--font-dark);
    transition: color 0.3s ease;
  }
  
  .lightbody {
    background: var(--background-light);
    transition: background 0.5s ease;
  }
  
  .darkbody {
    background: var(--background-dark);
    transition: background 0.5s ease;
  }