/* QuizBuilder Container */
.quiz-builder {
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: #f0f0f0;
  }
  
  /* Quiz Sidebar */
  .quiz-sidebar {
    position: fixed;
    top: 0;
    right: -50%;
    width: 50%;
    height: 100%;
    background-color: #fff;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
    transition: right 0.3s ease;
    z-index: 1000;
    overflow-y: auto;
  }
  
  .quiz-sidebar.open {
    right: 0;
    z-index: 300000;
  }
  
  .quiz-sidebar-toggle {
    position: fixed;
    top: 70px;  /* Positioned below the lesson sidebar toggle */
    right: 10px;
    background-color: #E6AF2E;  /* Green color to differentiate from lesson toggle */
    color: #fff;
    border: none;
    padding: 10px;
    cursor: pointer;
    z-index: 1001;
    border-radius: 10px;
    font-weight: bold;
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .quiz-sidebar-content {
    padding: 20px;
    z-index: 2000000;
  }
  
  /* Quiz Template Preview */
  .quiz-template-preview {
    flex: 1 1 40%;
    margin: 10px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .quiz-template-preview:hover {
    box-shadow: 0 0 15px 5px #d1aa4e; /* Add a purple glow on hover */
  }
  
  .quiz-template-name {
    font-weight: bold;
    margin-bottom: 5px;
    text-align: center;
  }
  
  .quiz-template-preview-container {
    width: 100%;
    height: auto; /* Adjust the height as needed */
    overflow: hidden; /* Hide overflow for each preview */
    transition: box-shadow 0.3s ease; /* Add a smooth transition for the box-shadow */
    border-radius: 10px; /* Add border-radius for rounded corners */
  }
  
  /* Quiz Template Editor */
  .quiz-template-editor {
    background-color: #f7d27c;  /* Light green background */
    border: 2px solid #e6bc5a;  /* Green border */
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 8px;
  }
  
  .quiz-question-input {
    margin-bottom: 20px;
  }
  
  .quiz-answer-input {
    margin-bottom: 15px;
    padding: 10px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #a58334;
  }
  
  .quiz-answer-input label {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }
  
  .quiz-answer-input input[type="radio"] {
    margin-right: 10px;
  }
  
  .quiz-template-editor button {
    background-color: #bb8300;
    color: white;
    border: none;
    padding: 10px 15px;
    margin: 5px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .quiz-template-editor button:hover {
    background-color: #eea700;
  }
  
  .quiz-template-editor-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
  
  .quiz-template-editor-button {
    background-color: transparent !important;
    border: none;
    cursor: pointer;
    margin-left: 10px;
  }
  
  .quiz-template-editor-button svg {
    fill: #a87600;
    width: 24px;
    height: 24px;
  }
  
  .quiz-template-editor-handle {
    display: flex;
    justify-content: center;
    cursor: grab;
    margin-top: 10px;
  }
  
  
  
  /* Quiz Preview Styles */
  .quiz-preview {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 20px;
  }
  
  .quiz-preview h3 {
    margin-bottom: 15px;
    color: #333;
  }
  
  .quiz-preview ul {
    list-style-type: none;
    padding: 0;
  }
  
  .quiz-preview li {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #e0e0e0;
    border-radius: 3px;
    transition: background-color 0.3s;
  }
  
  .quiz-preview li:hover {
    background-color: #f5f5f5;
  }
  
  .quiz-preview li.correct {
    background-color: #e8f5e9;
    border-color: #a5d6a7;
  }
  
  .quiz-preview .explanation {
    font-size: 0.9em;
    font-style: italic;
    color: #666;
    margin-top: 5px;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .quiz-sidebar {
      width: 100%;
      right: -100%;
    }
  
    .quiz-sidebar-toggle {
      top: 130px;  /* Further down on mobile to avoid overlapping */
    }
  }



  .correct-answer-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 12px;
    margin: 5px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    font-weight: bold;
  }
  
  .correct-answer-button:not(.correct) {
    background-color: #f0f0f0;
    color: #333;
  }
  
  .correct-answer-button.correct {
    background-color: #4caf50;
    color: white;
  }
  
  .correct-answer-button svg {
    margin-right: 5px;
  }

  .input-group {
    display: flex;
    flex-direction: row;
    align-items: center;
   padding:5px;
  }
  
  .input-group h5 {
    width:18%;
    font-size: 2vh;
    font-weight: bold;
    color: #8e24aa;
    /* margin-right: 10px; */
  }
  
  .input-group input[type="text"] {
    width: 80%;
    padding: 8px;
    border: 1px solid #ce93d8;
    border-radius: 4px;
    font-size: 14px;
  }
  
  
  .input-group input[type="number"] {
  margin-left: 20px;
    text-align: center;
    width: 10%;
    padding: 8px;
    border: 1px solid #ce93d8;
    border-radius: 4px;
    font-size: 14px;
  }
  
  .input-group input[type="text"]:focus,
  .input-group input[type="number"]:focus {
    outline: none;
    border-color: #8e24aa;
    box-shadow: 0 0 0 2px rgba(142, 36, 170, 0.2);
  }
  
  
  .ql-editor  {
    width: 100%;
  }
  
  .custom-quill-editor {
    width: 80%;
    margin: 0 auto;
  }
  
  /* Target the editable area */
  .custom-quill-editor .ql-editor {
    width: 100%;
  }
  
  /* Ensure the toolbar (if visible) is also 80% width */
  .custom-quill-editor .ql-toolbar {
    width: 100%;
  }

  .lessonSelectorButtonsContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  

  .json-editor {
    width: 100%;
    margin-bottom: 20px;
  }
  
  .json-editor textarea {
    width: 100%;
    min-height: 300px;
    padding: 15px;
    background-color: #fff;
    border: 2px solid #e6bc5a;
    border-radius: 8px;
    font-family: monospace;
    font-size: 14px;
    line-height: 1.5;
    color: #333;
    resize: vertical;
    transition: border-color 0.3s, box-shadow 0.3s;
  }
  
  .json-editor textarea:focus {
    outline: none;
    border-color: #bb8300;
    box-shadow: 0 0 0 2px rgba(187, 131, 0, 0.2);
  }
  
  .json-editor .error {
    color: #d32f2f;
    margin-top: 10px;
    font-size: 14px;
  }

  
.reactquill-input-group {
  display: flex;
  flex-direction: row;
  align-items: center;
 padding:10px;
 padding-bottom: 150px;
 background-color: #decff3;
 border-radius: 10px;

}

.reactquill-input-group h5 {
  width:18%;
  font-size: 2vh;
  font-weight: bold;
  color: #8e24aa;
  /* margin-right: 10px; */
}

.reactquill-input-group input[type="text"] {
  width: 80%;
  padding: 8px;
  border: 1px solid #ce93d8;
  border-radius: 4px;
  font-size: 14px;
}