.sidebar {
    position: fixed;
    top: 0;
    right: -50%;
    width: 50%;
    height: 100%;
    background-color: #fff;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
    transition: right 0.3s ease;
    z-index: 100000;
    overflow-y: auto; /* Make the sidebar scrollable */
  }
  
  .sidebar.open {
    right: 0;
  }
  
  .sidebar-toggle {
    position: fixed;
    font-weight: bold;
    top: 10px;
    right: 10px;
    background-color: #8f4ae2; /* Purple color */
    color: #fff;
    border: none;
    padding: 10px;
    cursor: pointer;
    z-index: 200100; /* Ensure the button is above the sidebar */
    border-radius: 10px; /* Make the button circular */
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
  }
  
  .sidebar-content {
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
  }
  
  .template-preview {
    flex: 1 1 40%;
    margin: 10px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .template-preview-container {
    width: 100%;
    height: auto; /* Adjust the height as needed */
    overflow: hidden; /* Hide overflow for each preview */
    transition: box-shadow 0.3s ease; /* Add a smooth transition for the box-shadow */
    border-radius: 10px; /* Add border-radius for rounded corners */
  }
  
  .template-preview-container:hover {
    box-shadow: 0 0 15px 5px #8f4ae2; /* Add a purple glow on hover */
  }
  
  .template-name {
    font-weight: bold;
    margin-bottom: 5px;
    text-align: center;
  }
  